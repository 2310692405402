<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Enterprise overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="enterprise">{{ enterprise.name }}</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="enterprise">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- <v-alert type="warning" v-if="isEnterpriseBillingSummaryReady && (!enterpriseBillingSummary || enterpriseBillingSummary.error)">
                        Billing data not available at this time.
                        <a href="#" class="text-alert" @click="navigateToBilling">Manage billing</a>
                    </v-alert> -->
                    <v-card class="mb-6 mt-8">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Enterprise settings</v-app-bar-title>
                            <v-spacer/>
                        </v-app-bar>
                        <v-card-text>
                            <!-- TODO: API TOKENS HERE !!! -->
                            <p>To obtain API client tokens for Cryptium Admin, please contact customer support. To obtain API client tokens for Cryptium ID, navigate to the realm settings and generate client tokens there.</p>

                            <p>
                                <router-link :to="{ name: 'enterprise-edit-brandprofile', params: { enterpriseId: $route.params.enterpriseId }}">Manage BrandProfiles</router-link>
                            </p>

                            <!-- <template v-if="isPermitSystemAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="red--text">
                                Danger zone
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="font-size: 0.8em;">
                            <p class="mt-4 mb-0 pb-0">
                                <router-link :to="{ name: 'enterprise-delete' }">How to delete this enterprise and related data</router-link>
                            </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
a.text-alert, a.text-alert:visited {
    color: #ffffff;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { ROLE_SYSTEM_ADMIN } from '@/sdk/constants';
// import UserList from '@/components/enterprise-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            enterprise: (state) => state.enterprise,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        // isPermitSystemAdmin() {
        //     return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes(ROLE_SYSTEM_ADMIN);
        // },
    },
    watch: {
        focus() {
            // TODO: reload data when user returns to this tab
        },
    },
    methods: {
    },
    mounted() {
    },
};
</script>
